<template>
  <v-container class="mkt-content-container mt-10">
    <px-marketplace-view-header title="Minhas compras"
                                :breadcrumbs="breadcrumbs"/>
    <px-compras-header @change="onRefresh"/>
    <div>
      <v-skeleton-loader type="list-item-two-line@10" class="mt-6" :loading="loading">
        <div v-if="!loading && !compras.content.length" align="center"
             class="headline mb-5 mt-10">
          Nenhuma compra encontrada
        </div>
        <v-expansion-panels class="mb-6 mt-6" multiple>
          <v-expansion-panel
            class="mt-5 rounded-card no-divider card-shadow"
            v-for="(compra) in compras.content"
            :key="compra.id"
          >
            <v-expansion-panel-header disable-icon-rotate>
              <template v-slot:actions>
                <v-btn icon @click.stop link :to="`compras/${compra.id}`">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
                <px-situacao-compra-marketplace-icon :situacao="compra.situacao"/>
              </template>
              <px-compras-item-header :compra="compra"/>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <px-compras-item-content :compra="compra" @change="onRefresh"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-skeleton-loader>
    </div>
    <v-pagination
      v-model="page"
      :length="pagesLimit"
      :total-visible="5"
    ></v-pagination>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import paginationMixin from '@/mixins/pagination-mixin';
import ComprasHeader from './ComprasHeader.vue';
import ComprasItemHeader from './ComprasItemHeader.vue';
import ComprasItemContent from './ComprasItemContent.vue';
import CompraService from './compras-service';

const LIMIT = 15;
const PAGE = 1;

export default {
  mixins: [paginationMixin],
  data() {
    return {
      page: PAGE,
      pagesLimit: 1,
      loading: false,
      compras: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: PAGE,
        limit: LIMIT,
        offset: 0,
        filter: '',
        sort: 'dataCompra',
        sortDesc: true,
      },
      optionsList: {
        page: PAGE,
        itemsPerPage: LIMIT,
        sortBy: '',
        sortDesc: false,
        multiSort: false,
      },
      breadcrumbs: [
        { text: 'Minhas compras' },
      ],
    };
  },
  computed: {
    ...mapGetters('listagemCompras', ['stringFilter']),
  },
  methods: {
    onChangeOptions() {
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getCompras();
    },
    setPagesLimit() {
      this.pagesLimit = Math.ceil(this.compras.total / LIMIT);
    },
    onRefresh() {
      this.getCompras();
    },
    getCompras() {
      this.loading = true;
      const { limit, offset } = this.listOptions;
      CompraService.getCompras({
        query: this.stringFilter,
        limit,
        offset,
      })
        .then(({ data }) => {
          this.compras = data;
          this.setPagesLimit();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    page(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      this.optionsList.page = newValue;
      this.onChangeOptions();
    },
  },
  created() {
    this.getCompras();
  },
  components: {
    pxComprasItemHeader: ComprasItemHeader,
    pxComprasItemContent: ComprasItemContent,
    pxComprasHeader: ComprasHeader,
  },
};
</script>
