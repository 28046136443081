<template>
  <v-row>
    <v-col cols="12" sm="4">
      <span class="font-weight-bold">
        {{ compra.fornecedor.pessoa.nome }}
      </span>
      <div>
        <small>
          fornecedor
        </small>
      </div>
    </v-col>
    <v-col cols="12" sm="4">
      <span class="font-weight-bold">
        {{ compra.company.pessoa.nome }}
      </span>
      <div>
      <small>
        empresa
      </small>
      </div>
    </v-col>
    <v-col cols="12" sm="2">
      <span class="font-weight-bold">
        {{ compra.dataCompra | date }}
      </span>
      <div>
        <small>
          pedido em
        </small>
      </div>
    </v-col>
    <v-col cols="12" sm="2">
      <span class="text--primary--color font-weight-bold body-1">
        {{ compra.total | currency }}
      </span>
      <div>
        <small>
          total
        </small>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    compra: Object,
  },
};
</script>
