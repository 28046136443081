<template>
  <div>
    <v-row dense class="mx-1">
      <v-spacer/>
      <v-btn icon @click="emitChange">
        <v-icon left>mdi-refresh</v-icon>
      </v-btn>
      <px-search-text label="Busque por um fornecedor ou produto"
                      v-model="searchText"
                      style="max-width: 450px"
                      class="rounded-tag-small"
                      :on-search="emitChange"/>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    searchText: {
      get() {
        return this.$store.getters['listagemCompras/searchText'];
      },
      set(searchText) {
        this.$store.dispatch('listagemCompras/searchText', searchText);
      },
    },
  },
  methods: {
    emitChange() {
      this.$emit('change');
    },
  },
};
</script>
